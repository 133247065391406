<template>
  <div>
    <a-row>
      <a-radio-group
        name="pickup_way"
        :default-value="currentPickupWay"
        @change="changePickupWay"
      >
        <a-radio value="post">
          商家邮寄
        </a-radio>
        <a-radio value="self_take">
          客户自提
        </a-radio>
      </a-radio-group>
      <a-popover trigger="hover">
        <template slot="content">
          <p>*如果您选择商家邮寄，平台将为您选择包邮或到付的方式进行快递寄送，若有特殊要求请在订单备注中说明。</p>
          <p>*如果您选择客户自提，平台将不提供邮寄服务，需要您联系销售人员，前往指定的地点去提取订购的物联卡。</p>
        </template>
        <a-space>
          <a-icon type="question-circle" />
        </a-space>
      </a-popover>
    </a-row>
    <a-row v-if="isSelfTake" style="margin-top: 20px">
      <a-descriptions
        :column="1"
        class="iotplt-descriptions"
      >
        <a-descriptions-item label="联系人">
          客服专员
        </a-descriptions-item>

        <a-descriptions-item label="电话">
          400-000-0000
        </a-descriptions-item>

        <a-descriptions-item label="时间">
          每周一至周五 上午 9：00 ~ 下午 17：30
        </a-descriptions-item>
      </a-descriptions>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'AgentPurchaseOrderModifyAddressPickupWay',
  data() {
    return {
      currentPickupWay: this.pickupWay,
      isSelfTake: this.pickupWay === 'self_take'
    }
  },
  props: {
    pickupWay: {
      type: String,
      required: true
    }
  },
  methods: {
    changePickupWay(e) {
      this.currentPickupWay = e.target.value
      this.isSelfTake = e.target.value === 'self_take'
      this.$emit('changePickupWay', this.currentPickupWay)
    }
  }
}
</script>

<style scoped>

</style>
