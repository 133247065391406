<template>
  <a-modal
    v-model="isShow"
    width="850px"
    centered
    :mask-closable="false"
    title="修改收货地址"
  >
    <template slot="footer">
      <a-button
        type="primary"
        @click="isShowNewModal = true"
        v-if="currentPickupWay === 'post'"
      >新增收货地址</a-button>
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button
        :loading="submitting"
        key="submit"
        type="primary"
        @click="handleSubmit"
      >
        确定
      </a-button>
    </template>
    <pickup-way-info :pickup-way="currentPickupWay" @changePickupWay="changePickupWay" />
    <div v-if="currentPickupWay === 'post'">
      <div style="margin-top: 10px">
        <agent-purchase-order-modify-address-search @submit="submitSearch" />
      </div>
      <div>
        <a-table
          size="middle"
          :scroll="{ x: 'max-content' }"
          :columns="columns"
          :data-source="data"
          :loading="loading"
          :pagination="false"
          :row-selection="{ type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
          row-key="id"
        />

        <pagination
          v-show="pagination.total_count > 0"
          :total-count="pagination.total_count"
          :page.sync="query.page"
          :per-page.sync="query.per_page"
          @change="fetchData"
        />
      </div>
    </div>

    <!-- 新增模态框 -->
    <new-address
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />
  </a-modal>
</template>

<script>
import { findReceiveAddresses } from '@/api/receive_address'
import { modifyAgentPurchaseAddress } from '@/api/agent_purchase_order'
import PickupWayInfo from '@/views/agent_purchase_orders/modify_address/PickupWay'
import AgentPurchaseOrderModifyAddressSearch from '@/views/agent_purchase_orders/modify_address/Search'
import Pagination from '@/components/Pagination'

export default {
  name: 'AgentPurchaseOrderModifyAddress',
  components: {
    Pagination,
    PickupWayInfo,
    AgentPurchaseOrderModifyAddressSearch,
    NewAddress: () => import('@/views/receive_addresses/New')
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    pickupWay: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: [],
      submitting: false,
      selectedRowKeys: [],
      isShowNewModal: false,
      currentPickupWay: '',
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    columns() {
      return [
        {
          title: '收货人',
          dataIndex: 'receiver',
          fixed: 'left'
        },
        {
          title: '联系电话',
          dataIndex: 'phone_number'
        },
        {
          title: '邮编',
          dataIndex: 'post_code'
        },
        {
          title: '所在地区',
          dataIndex: 'region_display'
        },
        {
          title: '详细地址',
          dataIndex: 'address_detail'
        }
      ]
    }
  },
  created() {
    this.currentPickupWay = this.pickupWay
    this.fetchData()
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    changePickupWay(pickupWay) {
      this.currentPickupWay = pickupWay
    },

    handleCancel(e) {
      this.isShow = false
    },

    chooseReceiveError() {
      this.$error({
        title: '提示',
        content: '请选择收货地址'
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findReceiveAddresses(
        Object.assign({ agent_id: this.$store.getters.userAgentId }, this.query)
      ).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.currentPickupWay === 'post' && this.selectedRowKeys.length === 0) {
        this.chooseReceiveError()
      } else {
        this.submitting = true
        const data = {
          address_id: this.selectedRowKeys[0],
          pickup_way: this.currentPickupWay
        }
        modifyAgentPurchaseAddress(this.id, data).then((res) => {
          if (res.code === 0) {
            // 关闭模态框
            this.isShow = false
            // 告知父组件已完成
            this.$emit('completed')
          }
          this.submitting = false
        })
      }
    }
  }
}
</script>
